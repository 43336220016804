require('./bootstrap');
require('./sidebar');


import {Datepicker, DateRangePicker} from 'vanillajs-datepicker';
import pl from "vanillajs-datepicker/locales/pl";


Object.assign(Datepicker.locales, pl);



(function () {
    'use strict'

    function getAgeFromPesel(pesel) {
        let year = parseInt(pesel.substring(0, 2), 10);
        let month = parseInt(pesel.substring(2, 4), 10) - 1;
        const day = parseInt(pesel.substring(4, 6), 10);
        if (month > 80) {
            year = year + 1800;
            month = month - 80;
        } else if (month > 60) {
            year = year + 2200;
            month = month - 60;
        } else if (month > 40) {
            year = year + 2100;
            month = month - 40;
        } else if (month > 20) {
            year = year + 2000;
            month = month - 20;
        } else {
            year += 1900;
        }
        let dob = new Date();
        dob.setFullYear(year, month, day);

        return dob;
    }

    // Fetch all the forms we want to apply custom Bootstrap validation styles to
    var forms = document.querySelectorAll('.needs-validation')
    // Loop over them and prevent submission
    Array.prototype.slice.call(forms)
        .forEach(function (form) {
            form.addEventListener('submit', function (event) {
                if (!form.checkValidity()) {
                    event.preventDefault()
                    event.stopPropagation()
                }

                form.classList.add('was-validated')
            }, false)
        });

    var datepickers = [].slice.call(document.querySelectorAll('.has-datepicker'))
    var datepickersList = datepickers.map(function (el) {
        return new Datepicker(el, {
            buttonClass: 'btn',
            language: 'pl',
            format: 'dd-mm-yyyy'
        });
    });

    var tooltipTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="tooltip"]'))
    var tooltipList = tooltipTriggerList.map(function (tooltipTriggerEl) {
        return new bootstrap.Tooltip(tooltipTriggerEl)
    })

    $('.has-select2').select2({
        theme: 'bootstrap-5'
    });

    $('.modal-header .close,.modal-footer .btn-secondary').click(function () {
        var myModalEl = document.getElementById($(this).parents('.modal').attr('id'));
        var modal = bootstrap.Modal.getInstance(myModalEl);
        modal.hide();
    });

    $.ajaxSetup({
        headers: {
            'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
        }
    });

    $('.button-delete-with-confirmation').on('click', function (e) {
        e.preventDefault();
        let url = $(this).attr('href');
        let title = $(this).data('alert-title');
        Swal.fire({
            title: title,
            text: '',
            type: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Usuń',
            cancelButtonText: 'Anuluj'
        }).then((result) => {
            if (result.value) {
                location.href = url;
            }
        });
    });


    $('.button-restore-with-confirmation').on('click', function (e) {
        e.preventDefault();
        let url = $(this).attr('href');

        Swal.fire({
            title: 'Czy na pewno przywrócić usunięty rekord?',
            text: '',
            type: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Przywróć rekord',
            cancelButtonText: 'Anuluj'
        }).then((result) => {
            if (result.value) {
                location.href = url;
            }
        });
    });

})()

